@import "../../styles/Variables.scss";

* {
  margin: 0;
  padding: 0;
}

.adventure-page {
  max-width: 100%;
}

#adventure {
  width: 98vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f9f9f7;
  font-family: "Aleo";
  padding: 20px; /* Add padding for better spacing on mobile */
  box-sizing: border-box; /* Ensure padding is included in the width and height */
}

/* Media query for larger screens */
@media (min-width: 600px) {
  #adventure {
    padding: 40px; /* Increase padding for larger screens */
  }
}

.adventure-header {
  margin: 20px;
  text-align: center;
  width: 100%;
}

.adventure-blurb {
  margin-top: 10px;
}

.kalendar-container {
  width: 100%;
  padding-bottom: 25px !important;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

/* small screen queries */
@media (max-width: 600px) {
  .kalendar-container {
    max-width: 300px !important;
  }

  .adventure-header {
    margin-top: 40px;
    width: 100%;
    font-size: 13px;
  }

  .block {
    min-width: 85px;
    max-height: 85px;
  }
}

.block {
  width: 20%;
  height: 20%;
  max-width: 300px; /* Limit the width of blocks */
  margin: 10px auto; /* Center blocks and add spacing */
  border: 1px solid black;
  background-color: #fff;
  margin: 0;
  padding: 15px;
  cursor: pointer;
}

/* Media query for larger screens */
@media (min-width: 600px) {
  .adventure-page h1 {
    font-size: 32px; /* Increase font size for larger screens */
  }

  .adventure-page .block {
    max-width: 400px; /* Increase max width for larger screens */
    padding: 20px; /* Increase padding for larger screens */
  }
}

.done {
  background-color: #e7e9ee;
}

.done:hover {
  background-color: #e6dfb1;
  border: 2px solid #ce9a0d;
}

.today {
  background-color: #dfedd3;
  border: 3px solid #64bf0e;
}

.calendar-day {
  width: 80% !important; //60px !important;
}
