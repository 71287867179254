//Use this for things like fonts and colors which we will pull into the various components

//Fonts
@font-face {
  font-family: "Aleo";
  src: url("./fonts/Aleo/Aleo-VariableFont_wght.ttf");
}

@font-face {
  font-family: "Libre";
  src: url("./fonts/Libre_Baskerville/LibreBaskerville-Regular.ttf");
}

@font-face {
  font-family: "Cinzel";
  src: url("./fonts/Cinzel_Decorative/CinzelDecorative-Regular.ttf");
}

$Aleo_font: "Aleo";
$Cinzel_font: "Cinzel";
$Libre_font: "Libre";

//Margins

$margin_large: 40px;
$margin_med: 20px;
$margin_small: 10px;

//Colours
$green: #0d441a;
$light-green: #116a26;
$bright-green: #08de3a;
$red: #651221;
$light-red: #911128;

@media (max-width: 500px) {
  $margin_med: 10px !important;
  $margin_large: 20px !important;
}
