@import "../../../../../styles/Variables.scss";

.link-wrapper {
  width: 300px;
}

.contact-link {
  color: black;
  align-items: center;
  font-family: $Aleo_font;

  p {
    font-size: 20px;
  }

  svg {
    width: 30px;
    height: 30px;
  }
}
