@import "./Variables.scss";

* {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

.full-page {
  min-height: 100vh;
  // overflow-x: hidden;
}

.full-page.col-start {
  gap: $margin_med;
}

/* Flex Containers */

.row-start {
  display: flex;
}

.row-end {
  display: flex;
  justify-content: right;
  gap: $margin_small;
}

.row-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: $margin_small;
}

.row-center-flex {
  display: flex;
  justify-content: center;
  align-items: inherit;
  gap: $margin_small;
  flex-wrap: wrap;
}

.row-between {
  gap: $margin_small;
  display: flex;
  justify-content: space-between;
}

.col-start {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.col-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.col-end {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.centered-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.invisibutton {
  border: none;
  background-color: transparent;
}
