@import "../../../../styles/Variables.scss";

.check-prompt {
  font-family: $Aleo_font;
  font-size: 2rem;

  span {
    font-weight: 700;
  }
}

.success {
  font-weight: 800;
  color: $green;
}

.fail {
  font-weight: 800;
  color: $red;
}

.bonus-reminder {
  font-family: $Cinzel_font;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  box-shadow: 2px 2px 5px rgb(54, 54, 54);
  border: 1px solid black;
  border-radius: 3px;
  margin-bottom: $margin_small;

  button {
    width: 100%;
    border-radius: 0 0 2px 2px;
    border: none;
    background-color: $light-green;
    box-shadow: inset 0 0 5px rgb(8, 40, 8);
    height: 40px;
    text-align: center;
    font-family: $Cinzel_font;
    font-size: 15px;
    color: white;
  }

  button:hover {
    filter: brightness(1.2);
  }

  button:disabled {
    filter: brightness(0.8);
  }

  button:disabled:hover {
    filter: brightness(0.8);
  }

  input {
    width: 100%;
    box-sizing: border-box;
    padding: 5px;
    height: 40px;
    text-align: center;
    font-family: $Cinzel_font;
    font-size: 15px;
    border-radius: 2px 2px 0 0;
    border: none;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none; /* Chrome, Safari, Edge, Opera */
    margin: 0;
  }
}

@media (max-width: 600px) {
  .input-container {
    width: 100%;
  }

  .check-prompt,
  .bonus-reminder,
  .success,
  .fail {
    text-align: center;
  }
}
