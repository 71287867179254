@import "../../../styles/Variables.scss";

#contact {
  height: 100vh;
  min-height: unset;
  max-height: 100vh;
}

#contact-links {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 500px) {
  #contact-links {
    justify-content: center;
  }
}
