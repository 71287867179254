@import "../../styles/Variables.scss";

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #1a1a1a; //#fff;
  color: #fff;
  border-bottom: 1px solid #ccc;
  z-index: 1000;
  font-family: "Aleo";
  font-weight: 300;
}

.toggle {
  width: 100%;
  text-align: center;
}

.navbar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}

.burger-icon {
  width: 10px;
  height: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.burger-icon .line {
  width: 100%;
  height: 2px;
  border-radius: 25%;
  background-color: #1a1a1a;
  transition: all 0.3s ease;
}

.menu {
  display: none;
  flex-direction: column;
  align-items: center;
  //background-color: #fff;
  background-color: #333; //#fff;
  color: #fff !important;
  width: 100%;
  padding: 0;
}

.back-button {
  padding-left: 20px;
  background-color: rgb(26, 26, 26);
  color: #fff !important;
  font-size: 10px;
  font-family: "Aleo";
}

.move-left {
  padding-right: 30px;
}

.menu.open {
  display: flex;
}

.nav-link {
  text-decoration: none;
  //color: #333;
  color: #fff !important;
  padding: 7px;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

.nav-link:last-child {
  border-bottom: none;
}

.active {
  font-weight: 900;
}