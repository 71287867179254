@import "../../../../styles/Variables.scss";

.show-character-sheet {
  position: fixed;
  left: $margin_med;
  top: calc(65px + $margin_med);
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: $light_red;
  border: none;
  box-shadow: 2px 2px 5px rgb(54, 54, 54);
  z-index: 101;

  svg {
    height: 40px;
    width: 40px;
    g {
      stroke: white;
    }
  }
}

.character-sheet {
  position: fixed;
  top: calc(55px + $margin_med);
  left: $margin_small;
  z-index: 100;
  padding: $margin_small;
  //   padding-bottom: calc(50px + #{$margin_small} + #{$margin_med});
  background-color: white;
  box-shadow: 2px 2px 5px rgb(54, 54, 54);
}

.character-sheet.hidden {
  transform: scale(0);
  display: block;
}

.show {
  transform: scale(1);
  transition: transform 0.3s ease;
}

.char-abilities,
.char-stat,
.header {
  flex-wrap: wrap;
  column-gap: $margin-small;
  row-gap: 0 !important;
}

.char-stats {
  gap: $margin_small;
}

.stat {
  padding: $margin_small;
  box-shadow: inset 0 0 5px rgb(54, 54, 54);
  text-align: center;
  margin-bottom: $margin_small;
  width: 70px;
  box-sizing: border-box;
  font-family: $Libre_font;
  font-size: 15px;

  h3 {
    font-family: $Libre_font;
    font-size: 19px;
  }
}

.stat.med {
  width: 105px;
}

.stat.large {
  width: calc(210px + $margin_med);
}

.name {
  margin-left: 80px;
  width: 150px !important;
}

.speed {
  width: 90px;
}

.hp-ac {
  width: 60px;
}

@media (max-width: 600px) {
  .show-character-sheet {
    top: calc(45px + $margin_small);
  }

  .character-sheet {
    left: 0;
    top: 40px;
    // width: calc(100vw);
  }
}
