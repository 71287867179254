@import "../../../styles/Variables.scss";

* {
  margin: 0;
  padding: 0;
}

.register-link {
  font-family: "Aleo";
  margin: 10px;

  a {
    cursor: pointer;
    text-decoration: underline !important;
    color: rgb(17, 85, 204);
  }
}

.account-page-logo {
  width: 50% !important;
}

.authenticate-form {
  margin: $margin_med;
  padding: $margin_med;
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  box-shadow: 2px 2px 5px rgb(54, 54, 54);
  width: 700px;
  max-width: 100%;
  box-sizing: border-box;
  align-items: start !important;

  input {
    width: calc(100% - $margin_med);
    margin-bottom: $margin_small;
    padding: $margin_small;
    border-radius: 2px;
    border: 1px solid rgb(54, 54, 54);
    font-family: $Aleo_font;
    font-size: 1rem;
  }

  label {
    font-family: $Aleo_font;
    font-size: 1.2rem;
  }

  button {
    height: 40px;
    width: 100px;
    font-family: $Aleo_font;
    color: white;
    margin-right: 5px;
  }

  button:hover {
    filter: brightness(130%);
  }
}

.input-group {
  width: calc(100% - 20px);
  box-sizing: border-box;
}

.clear-button {
  background-color: $red;
}

.login-button {
  background-color: $green;
}

.success-message {
  margin-top: 10px;
  color: $light-green;
  font-family: $Aleo_font;
  font-size: 1.2rem;
}

.failure-message {
  margin-top: 10px;
  color: $light-red;
  font-family: $Aleo_font;
  font-size: 1.2rem;
}
