@import "../../../../styles/Variables.scss";

.combat-turn {
  padding: $margin_med;
  box-shadow: inset 0 0 5px rgb(54, 54, 54);
  margin-bottom: $margin_med;
  font-family: $Libre_font;
  h3 {
    font-size: 1.5rem;
    font-family: Aleo_font;
  }

  .next-turn {
    margin-bottom: 0;
    margin-top: $margin_small;
  }
}

.round-header {
  font-size: 2rem !important;
  font-family: $Aleo_font !important;
}

.next-turn {
  width: 100%;
  max-width: 300px;
  border-radius: 0 0 2px 2px;
  border: none;
  background-color: #116a26;
  box-shadow: inset 0 0 5px rgb(8, 40, 8), 2px 2px 5px rgb(54, 54, 54);
  height: 40px;
  text-align: center;
  font-family: "Cinzel";
  font-size: 15px;
  color: white;
  margin-bottom: $margin_med;
}

.combat-choice-wrapper {
  display: flex;
  gap: $margin_small;
  margin-bottom: $margin_med;
  flex-wrap: wrap;

  button {
    width: 100%;
    max-width: 300px;
    flex-grow: 1;
    border-radius: 0 0 2px 2px;
    border: none;
    background-color: #116a26;
    box-shadow: inset 0 0 5px rgb(8, 40, 8), 2px 2px 5px rgb(54, 54, 54);
    // height: 40px;
    text-align: left;
    font-family: "Cinzel";
    font-size: 1.2rem;
    color: white;
    padding: $margin_small;
  }
}

.help-text {
  font-size: 0.7rem;
  font-family: $Libre_font;
}

.attack-die-box {
  font-family: $Libre_font;
  display: flex;
  max-width: 400px;
  margin-top: $margin_small;
  box-shadow: 2px 2px 5px rgb(54, 54, 54);
  button {
    padding: $margin_small;
    border-radius: 0 2px 2px 0;
    background-color: $light-red;
    color: white;
    border: 1px solid black;
  }

  input {
    padding: $margin_small;
    border-radius: 2px 0 0 2px;
    flex-grow: 1;
    border: 1px solid black;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none; /* Chrome, Safari, Edge, Opera */
    margin: 0;
  }
}
