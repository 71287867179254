@import "../../../styles/Variables.scss";

#about {
  padding: $margin_med 10%;
  h1 {
    font-family: $Aleo_font;
    font-size: 80px;
    text-align: center;
  }

  p {
    font-family: $Cinzel_font;
    font-size: 1.2rem;
    text-align: center;
  }

  h2 {
    font-size: 60px;
    font-family: $Aleo_font;
    margin-bottom: calc($margin_med * -1);
  }

  .disclaimer {
    font-size: 1rem;
  }

  img {
    width: 550px;
    border-radius: $margin_small;
    box-shadow: 2px 2px 5px rgb(54, 54, 54);
    max-width: 110%;
  }
}

@media (max-width: 500px) {
  #about {
    h1 {
      font-size: 40px;
    }

    p {
      font-size: 0.8rem;
    }
  }
}
