@import "../../../../styles/Variables.scss";

.header-link {
  width: 100%;
  width: 30%;
  a {
    width: 100%;
    text-align: center;
  }

  svg {
    width: 100%;
    .st0 {
      fill: white;
    }
  }

  h2 {
    font-family: $Aleo_font;
    color: black;
    text-decoration: none;
  }
}

.header-link:first-child {
  margin-top: 0 !important;
}

@media (max-width: 500px) {
  .header-link {
    h2 {
      font-size: 10px;
    }

    img {
      width: 100%;
    }
  }
}
