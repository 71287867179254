@import "../../../styles/Variables.scss";

#header {
  .card:first-child {
    margin-top: $margin_med;
  }
}

.contact-icon {
  padding-left: 15px;
  padding-top: 10px;
}

.card {
  background-color: rgb(255, 255, 255);
  z-index: 0;
  padding: 10px;
  border-radius: 2px;
  box-shadow: 2px 2px 5px rgb(54, 54, 54);
}

#card-logo {
  width: 50%;
}

#card-blurb {
  width: 1000px;
  min-width: 60%;
  p {
    font-family: $Cinzel_font;
    font-weight: 900;
    font-size: 24px;
    text-align: center;
  }
}

#card-links {
  min-width: 40%;
  width: 600px;
  margin-bottom: $margin_med;
  gap: $margin_med;
}

.main-logo {
  width: 100%;
  img {
    width: 80%;
  }
}

@media (max-width: 500px) {
  #header {
    padding: 0 $margin_small;
    margin: 15px 0;
  }

  #card-logo,
  #card-links,
  #card-blurb {
    width: calc(100% - 20px);

    p {
      font-size: 12px;
    }

    h2 {
      font-size: 10px;
    }
  }

  #card-links {
    flex-wrap: wrap;
    justify-content: center;
  }
}
