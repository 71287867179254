@import "../../../../styles/Variables.scss";

.save-widget {
  position: absolute;
  top: $margin_med;
  right: $margin_med;
  background-color: black;
  color: white;
  font-family: $Cinzel_font;
  padding: $margin_small;
  box-shadow: 2px 2px 5px rgb(54, 54, 54);
  z-index: 99;

  svg {
    width: 20px;
    height: 20px;
    margin-right: $margin_small;
  }
}

@media (max-width: 600px) {
  .save-widget {
    margin-top: 22px;
  }
}