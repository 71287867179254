@import "../../../../styles/Variables.scss";
.unselected {
  display: none;
}

.choices-box {
  width: 100%;
}

.choice-container {
  width: 100%;
  flex-wrap: wrap;
  gap: $margin_med;
  margin-bottom: $margin_med;
  button {
    color: white;
    width: 220px;
    min-width: calc(50% - $margin_small);
    background-color: $light-green;
    border: 1px solid black;
    min-height: 50px;
    box-shadow: 2px 2px 5px rgb(54, 54, 54);
    font-family: $Libre_font;
    font-size: 1rem;
    text-align: justify;
    padding: $margin_small;
  }

  button:hover {
    cursor: pointer;
    filter: brightness(130%);
  }

  button:disabled {
    background-color: $green;
    cursor: initial;
  }
}

@media (max-width: 600px) {
  .choice-container {
    button {
      width: 100%;
      text-align: center;
    }
  }
}
