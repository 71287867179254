@import "../../../styles/Variables.scss";

.logout-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Aleo";
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%; /* Default width for mobile */
  max-width: 400px; /* Maximum width for larger screens */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.popup-prompt {
  color: black !important;
  margin-bottom: 20px;
}

.popup-button {
  height: 40px;
  width: 100px;
  font-family: $Aleo_font;
  color: white;
  margin-right: 5px;
}

.popup-button:hover {
  filter: brightness(130%);
}

.popup-confirm {
  background-color: $red;
}

.popup-deny {
  background-color: $green;
}

/* Media query for larger screens */
@media (min-width: 600px) {
  .popup-content {
    width: 80%; /* Adjust width for larger screens */
  }
}
