@import "../../../../styles/Variables.scss";

.end-of-day {
  font-family: $Aleo_font;

  a,
  p {
    color: $green;
    font-size: 1.2rem;
  }
}
