@import "../../../../../styles/Variables.scss";

.prog-display {
  width: 300px;
  font-family: $Aleo_font;
  font-size: 20px;
}

.loading {
  color: $green;
}

.sent {
  color: $green;
}

.error {
  color: $red;
  font-size: 15px;
}

.hidden {
  display: none !important;
}
