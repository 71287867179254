@import "../../styles/Variables.scss";

.burger {
  width: 100px;
  height: 100px;
  position: fixed;
  z-index: 1;
}

.burger-icon {
  margin: 20px;
  margin-bottom: 0;
  width: 30px !important;
  height: 30px !important;
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 2px;
  box-shadow: 1px 1px 3px rgb(54, 54, 54);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;

  &.open {
    padding-left: 44px;
    padding-right: 41px;
    border-radius: 0;
    border: 2px solid #ccc;
    border-bottom: 0;
    box-shadow: none;
  }
}

.burger-icon .line {
  width: 36px;
  height: 4px;
  background-color: #333;
  border-radius: 25%;
  transition: all 0.3s ease;
}

.burger-menu {
  width: 100px;
  height: 50%;
  margin-left: 20px;
  background-color: #fff;
  border: 2px solid #ccc;
  padding: 10%;
  border-radius: 2px;
  box-shadow: 1px 1px 3px rgb(54, 54, 54);
  display: none;
  flex-direction: column;
  transition: all 0.6s ease 0.3s;

  &.open {
    border-radius: 0;
    display: flex;
    box-shadow: none;
  }
}

.burger-menu a {
  text-decoration: none;
  color: #333;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
  text-align: center;
  font-family: "Aleo";
  cursor: pointer;
}

.burger-menu a.active {
  font-weight: 900;
}

.burger-menu a:last-child {
  border-bottom: none;
}

// open styles
.burger.open {
  height: max-content;

  .burger-icon.open .line:nth-child(1) {
    transform: rotate(45deg) translate(8px, 8px);
  }

  .burger-icon.open .line:nth-child(2) {
    opacity: 0;
  }

  .burger-icon.open .line:nth-child(3) {
    transform: rotate(-45deg) translate(8px, -8px);
  }
}
