@import "../../../../styles/Variables.scss";

#email {
  width: 70vw;
  flex-grow: 1;
  height: 50px;
  display: flex;
  flex-direction: column;
  max-height: 400px;
  margin-top: $margin_med;

  textarea {
    border-radius: 2px;
    flex-grow: 1;
    padding: $margin_small;
    resize: none;
    margin-bottom: $margin_small;
    box-shadow: inset 1px 1px 3px black;
    font-family: $Libre_font;
    font-size: 1rem;
  }

  button {
    height: 40px;
    width: 100px;
    font-family: $Aleo_font;
    color: white;
  }

  button:hover {
    filter: brightness(130%);
  }

  h2,
  label {
    font-family: $Aleo_font;
    display: block;
  }

  label {
    font-size: 20px;
  }

  form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .inputs {
    flex-wrap: wrap;
  }

  .input-wrapper {
    width: 300px;
    flex-grow: 1;
  }

  input {
    width: calc(100% - 20px);
    height: 30px;
    box-shadow: inset 1px 1px 2px rgb(100, 100, 100);
    font-family: $Libre_font;
    padding: 0 $margin_small;
    margin-bottom: 10px;
    border-radius: 2px;
    font-size: 1rem;
  }

  .bottom-bar {
    flex-wrap: wrap-reverse;
  }
}

#clear-btn {
  background-color: $red;
}

#send-btn {
  background-color: $green;
}

@media (max-width: 500px) {
  #email {
    width: calc(100% - 40px);
  }
}
