@import "../../../styles/Variables.scss";

#daily-adventure {
  background-color: #e7e9ee;
  padding-bottom: $margin_large;
}

.page {
  width: 80%;
  max-width: 1200px;
  margin-top: $margin_large;
  padding: $margin_med;
  align-items: flex-start !important;

  h1 {
    font-family: $Aleo_font;
    font-size: 2.5rem;
    margin-bottom: $margin_med;
  }

  h2 {
    font-family: $Libre_font;
    font-size: 1.5rem;
    margin-bottom: $margin_small;
  }
}

.adventure-header {
  align-items: center;
  gap: $margin_med;
  margin-bottom: $margin_med;
  flex-wrap: wrap;
  img {
    width: 150px;
    height: 150px;
  }
}

.adventure-text {
  display: flex !important;
  font-family: $Libre_font;
  font-size: 1rem;
  margin-bottom: $margin_small;
  text-align: justify;
  white-space: pre-wrap;
}

@media (max-width: 600px) {
  .adventure-header {
    justify-content: center;
    text-align: center;
    width: 85%;
  }
}
